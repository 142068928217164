import { useTranslation } from '@/app/i18n/client'
import { SupportedChain } from '@/config/networks'
import { useTokenRelations } from '@/hooks/useTokenRelations'
import { FlowRate } from '@/types'
import {
  cn,
  formatFiatValue,
  getOriginalFlowRate,
  getStreamedAmountForPeriod,
  removeLocalization,
  toFixedFromString
} from '@/utils'
import { SuperTokenInfo, TokenInfo } from '@superfluid-finance/tokenlist'
import { FC, useMemo } from 'react'
import { formatUnits, parseUnits } from 'viem'
import { NumericalInput } from './NumericalInput'
import { TokenChip } from './TokenChip'
import { TokenSelectDialog } from './TokenSelectDialog'
import { usePrices } from './providers/PricesProvider'
import { Button } from './ui/button'

const Value: FC<{
  token?: TokenInfo | SuperTokenInfo
  price?: number
  value?: string
}> = ({ token, price, value }) => {
  const { getSuperTokenOf } = useTokenRelations()

  const tokenAddress = token?.address
  const superTokenAddress = getSuperTokenOf(tokenAddress)?.address

  return superTokenAddress && price ? (
    <p className='text-xs text-gray-400 mr-auto self-center'>{formatFiatValue(Number(value), price)}</p>
  ) : null
}

type QuickSetMode = 'max' | 'matchInflow' | 'none'

type TokenInputProps = {
  title: string
  tokens: SuperTokenInfo[]
  selectedNetwork: SupportedChain
  onAmountChange: (amount: string) => void
  onTokenSelect?: (token: SuperTokenInfo) => void
  token?: TokenInfo | SuperTokenInfo
  flowRate?: FlowRate
  amount?: string
  quickSetMode?: QuickSetMode
  disabled?: boolean
  disableTokenChip?: boolean
  disableValidation?: boolean
  className?: string
  dataTestId?: string
  balance: bigint
  netFlow?: bigint
  displaySelectedTokenMode?: 'underlying' | 'super'
  displayBalanceMode?: 'mixed' | 'underlying' | 'super'
}

export const TokenInput: FC<TokenInputProps> = ({
  title,
  tokens,
  selectedNetwork,
  onTokenSelect,
  onAmountChange,
  token,
  balance,
  netFlow = 0n,
  flowRate,
  amount,
  quickSetMode = 'max',
  disabled,
  disableValidation,
  disableTokenChip = false,
  className,
  dataTestId,
  displaySelectedTokenMode,
  displayBalanceMode
}) => {
  const { t } = useTranslation()
  const { getSuperTokenOf, getUnderlyingTokenOf } = useTokenRelations()
  const isBalanceValid = useMemo(
    () =>
      disableValidation ||
      balance >=
        (flowRate ? getStreamedAmountForPeriod(flowRate, 'day') : parseUnits(amount ?? '0', token?.decimals ?? 18)),
    [disableValidation, balance, flowRate, amount, token]
  )

  const { getPrice } = usePrices()

  const monthlyInflowFormatted = useMemo(() => {
    const matchTo = netFlow && netFlow > 0n ? netFlow : 0n

    return removeLocalization(toFixedFromString(getOriginalFlowRate(matchTo, 'month').amountEth))
  }, [netFlow])

  const maxButtonSettings = useMemo(() => {
    switch (quickSetMode) {
      case 'max':
        return {
          display: balance > 0n,
          formatted: formatUnits(balance, token?.decimals ?? 18)
        }
      case 'matchInflow':
        return { display: netFlow > 0n, formatted: monthlyInflowFormatted }
      default:
        return {
          display: false,
          formatted: ''
        }
    }
  }, [quickSetMode, balance, monthlyInflowFormatted, token, netFlow])

  return (
    <div data-testid={dataTestId} className={cn('flex flex-col bg-black p-4 rounded-lg', className)}>
      {!disabled && <p className='text-gray-400 text-sm'>{title}</p>}

      <div className='flex items-center justify-between'>
        {!disabled && (
          <NumericalInput
            className='border-none bg-transparent ring-0 text-2xl font-semibold w-full'
            data-testid='custom-flow-rate-input-field'
            value={flowRate?.amountEth ?? amount ?? ''}
            onUserInput={onAmountChange}
            disabled={disabled}
          />
        )}

        {disabled && <p className='text-gray-400 text-sm'>{title}</p>}

        <div data-testid={dataTestId} className='flex gap-2'>
          <TokenSelectDialog
            tokens={tokens}
            selectedNetwork={selectedNetwork}
            onTokenSelect={onTokenSelect ? token => onTokenSelect(token as SuperTokenInfo) : undefined}
            displayBalanceMode={displayBalanceMode}
            displayTokenMode={displaySelectedTokenMode}
          >
            <TokenChip
              dataTestid='select-token-button'
              token={
                (displaySelectedTokenMode === 'underlying'
                  ? getUnderlyingTokenOf(token?.address)
                  : displaySelectedTokenMode === 'super'
                    ? getSuperTokenOf(token?.address)
                    : token) ?? token
              }
              disabled={disableTokenChip}
            />
          </TokenSelectDialog>
          {/* {!disabled && flowRate && (
            <Select
              onValueChange={onPeriodChange}
              defaultValue='month'
              value={flowRate?.period}
            >
              <SelectTrigger data-testid="period-select-button" className='w-24 border-none bg-gray-800 font-semibold px-2 h-6'>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup className='bg-black'>
                  <SelectLabel>Period</SelectLabel>
                  {timePeriods.map((interval) => (
                    <SelectItem
                      className='font-semibold'
                      value={interval}
                      key={interval}
                    >
                      /{interval}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          )} */}
        </div>
      </div>

      <div className='flex'>
        {!disabled && (
          <Value
            token={token}
            value={flowRate?.amountEth ?? amount}
            price={getPrice(getSuperTokenOf(token?.address)?.address)}
          />
        )}

        {token && !disabled && (
          <div className='flex items-center text-xs ml-auto'>
            <div data-testid={`${token.symbol}-balance`} className='flex gap-1'>
              <p className={isBalanceValid || disabled ? 'text-gray-400' : 'text-red-error'}>{t('balance.default')}:</p>
              <p className={isBalanceValid || disabled ? 'text-white' : 'text-red-error'}>
                {toFixedFromString(formatUnits(balance, token.decimals))}
              </p>
            </div>

            {maxButtonSettings.display && (
              <Button
                data-testid='max-button'
                className='text-xs text-brand-main p-1 h-5 underline'
                onClick={() => onAmountChange(maxButtonSettings.formatted)}
                variant='ghost'
              >
                {quickSetMode === 'max' ? t('max') : t('matchInflow')}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
