import { cn } from '@/utils'
import { SuperTokenInfo, TokenInfo } from '@superfluid-finance/tokenlist'
import { FC } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'

const SuperTokenBorder: FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 36 36'
      className='absolute top-0 left-0 animate-spin animate-duration-[8000ms]'
    >
      <title>SuperToken Border</title>
      <clipPath id='clip'>
        <polygon points='18,18, 30.5,0 36,10.2' />
      </clipPath>

      <mask id='mask'>
        <rect x='-3' y='-3' width='42' height='42' fill='white' />
        <polygon points='18,18, 30.5,0 36,10.2' fill='black' />
      </mask>

      <circle mask='url(#mask)' r='17.5px' cx='18' cy='18' stroke='green' strokeWidth='1' fill='transparent' />
      <circle
        clipPath='url(#clip)'
        r='17px'
        cx='18'
        cy='18'
        strokeDasharray='2'
        stroke='green'
        strokeWidth='2'
        fill='transparent'
      />
    </svg>
  )
}

type TokenIconProps = {
  token: TokenInfo | SuperTokenInfo
  className?: string
}

export const TokenIcon: FC<TokenIconProps> = ({ token, className }) => {
  return (
    <Avatar className={cn('w-5 h-5 flex justify-center items-center shrink-0', className)}>
      <>
        {token.tags?.includes('supertoken') && <SuperTokenBorder />}
        <AvatarImage className='w-4 h-4 rounded-full bg-black' src={token.logoURI} alt={token.symbol} />
        <AvatarFallback>{token.symbol}</AvatarFallback>
      </>
    </Avatar>
  )
}
