import { cn } from '@/utils'
import { lightInset } from '@/utils/style'
import { FC, ReactNode } from 'react'
import highlight from '../../public/highlight.svg'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card'

type CardSkeletonProps = {
  children?: ReactNode
  cta: ReactNode
  title?: string | ReactNode
  description?: string | ReactNode
  error?: string | null
  warning?: string | null
  className?: string
}

export const CardSkeleton: FC<CardSkeletonProps> = ({
  children,
  cta,
  title,
  description,
  error,
  warning,
  className
}) => {
  return (
    <Card
      data-testid='card'
      className={cn(
        'w-full min-w-[320px] max-w-[420px]',
        'rounded-lg border border-gray-600',
        'bg-black bg-cover bg-opacity-70 backdrop-blur-[4px] transition-height',
        lightInset,
        className
      )}
      style={{ backgroundImage: `url(${highlight.src})` }}
    >
      {(title || description) && (
        <CardHeader data-testid='card-header' className='space-y-1'>
          <CardTitle className='text-2xl capitalize'>{title}</CardTitle>

          <CardDescription className='capitalize'>{description}</CardDescription>
        </CardHeader>
      )}

      <CardContent data-testid='card-content' className='flex p-2 flex-col gap-2 bg-opacity-10'>
        {children}
      </CardContent>
      <CardFooter data-testid='card-footer' className='flex flex-col p-2 pt-3'>
        <div className='w-full'>{cta}</div>
        <div
          data-testid='error'
          className={cn('flex items-center text-xs text-red-error transition-all h-0 text-center', error && 'h-12 p-2')}
        >
          {error ? error : ''}
        </div>
        <div
          data-testid='warning'
          className={cn(
            'flex items-center text-xs text-yellow-500 transition-all h-0 text-center',
            !error && warning && 'h-12 p-2'
          )}
        >
          {!error && warning ? warning : ''}
        </div>
      </CardFooter>
    </Card>
  )
}
