import { Currency } from '@/config/coingecko'
import { ChainId } from '@/config/networks'
import { useTorexTokens } from '@/hooks/useTorexTokens'
import { FetchTokenPriceByContractResponse } from '@/utils/fetch'
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Address } from 'viem'
import { useChainId } from 'wagmi'

const currencies = ['usd']

type Prices = {
  prices: FetchTokenPriceByContractResponse
  update: (prices: FetchTokenPriceByContractResponse) => void
  refetch: () => void
}

export const PricesContext = createContext<Prices>({
  prices: {},
  update: (_: FetchTokenPriceByContractResponse) => {},
  refetch: () => {}
})

export const PricesProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const { tokens } = useTorexTokens()
  const userChainId = useChainId() as ChainId
  const [prices, setPrices] = useState<FetchTokenPriceByContractResponse>({})

  const update = useCallback((prices: FetchTokenPriceByContractResponse) => {
    setPrices(prev => Object.assign({}, prev, prices))
  }, [])

  const fetchPrices = useCallback(async () => {
    try {
      const result: FetchTokenPriceByContractResponse = await (
        await fetch('/api/pricing', {
          method: 'POST',
          body: JSON.stringify({
            chainId: userChainId,
            currencies,
            contracts: tokens.map(token => token.address)
          })
        })
      ).json()

      setPrices(result)
    } catch (e) {
      console.error(
        new Error(`Fetching tokens \n ${tokens.join('\n')} failed.`, {
          cause: e
        })
      )
    }
  }, [userChainId, tokens])

  useEffect(() => {
    fetchPrices()
  }, [fetchPrices])

  const value = useMemo(() => ({ prices, update, refetch: fetchPrices }), [prices, update, fetchPrices])

  return <PricesContext.Provider value={value}>{children}</PricesContext.Provider>
}

export function usePrices() {
  const { prices } = useContext(PricesContext)

  const getPrice = useCallback(
    (tokenAddress?: Address, currency: Currency = 'usd') => {
      if (tokenAddress && prices[tokenAddress]) {
        return prices[tokenAddress][currency]
      }

      return 0
    },
    [prices]
  )

  return {
    prices,
    getPrice
  }
}
