import { useQuery } from '@tanstack/react-query'
import detectProxy from 'evm-proxy-detection'
import { ProxyType } from 'evm-proxy-detection/build/esm/types'
import { useEffect, useState } from 'react'
import {
  Address,
  PublicClient,
  WaitForTransactionReceiptParameters,
  WaitForTransactionReceiptReturnType,
  WriteContractReturnType
} from 'viem'
import {
  UseWaitForTransactionReceiptReturnType,
  useAccount,
  usePublicClient,
  useWaitForTransactionReceipt
} from 'wagmi'
import {
  arbitrum,
  base,
  baseGoerli,
  baseSepolia,
  gnosis,
  goerli,
  mainnet,
  optimism,
  polygon,
  sepolia
} from 'wagmi/chains'

export const useIsContractWallet = (address?: Address) => {
  const publicClient = usePublicClient()

  return useQuery({
    enabled: !!address && !!publicClient,
    queryKey: ['isContractWallet', address],
    queryFn: () => {
      if (!address || !publicClient) {
        throw Error('no address or public client')
      }

      return isContractWallet(publicClient, address)
    },
    initialData: {}
  })
}

export const useSafeWaitForTransaction = (
  params: Parameters<typeof useWaitForTransactionReceipt>[0]
): UseWaitForTransactionReceiptReturnType => {
  const { address, chain } = useAccount()
  const {
    data: { type }
  } = useIsContractWallet(address)

  const isSafeWallet = type === ProxyType.Safe

  const [safeResult, setSafeResult] = useState<WriteContractReturnType | undefined>()

  const waitResponse = useWaitForTransactionReceipt({
    ...params,
    chainId: chain?.id,
    hash: safeResult,
    query: {
      enabled: !!safeResult
    }
  })

  useEffect(() => {
    if (!params?.hash || !chain) {
      return
    }

    if (isSafeWallet) {
      resolveSafeTx(chain.id, params.hash).then(setSafeResult)
    } else {
      setSafeResult(params.hash)
    }
  }, [chain, isSafeWallet, params])

  return waitResponse
}

export const safeWaitForTransactionReceipt = async (
  publicClient: PublicClient,
  params: WaitForTransactionReceiptParameters & {
    address: Address
  }
): Promise<WaitForTransactionReceiptReturnType> => {
  if (!publicClient.chain) {
    throw new Error('no chain on public client')
  }

  const { type } = await isContractWallet(publicClient, params.address)

  //todo: Safes *can* be deployed without a proxy
  const isSafe = type === ProxyType.Safe

  if (!isSafe) {
    // Fall back to the default implementation
    return publicClient.waitForTransactionReceipt(params)
  }

  //try to resolve the underlying transaction
  const resolvedTx = await resolveSafeTx(publicClient.chain.id, params.hash)

  return publicClient.waitForTransactionReceipt({ ...params, hash: resolvedTx })
}

export const isContractWallet = async (
  publicClient: PublicClient,
  address: Address
): Promise<{
  isContract?: boolean
  type?: ProxyType
}> => {
  const bytecode = await publicClient.getCode({ address })
  const isContract = bytecode && bytecode.length > 2

  if (!isContract) {
    return {
      isContract: false,
      type: undefined
    }
  }

  // @ts-ignore
  const result = await detectProxy(address, publicClient.request)

  return {
    isContract: true,
    type: result?.type
  }
}

function delay(milliseconds: number) {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds)
  })
}

type TxServiceApiTransactionResponse = {
  safe: Address
  to: Address
  data: Address
  blockNumber: number
  transactionHash: Address
  safeTxHash: Address
  executor: Address
  isExecuted: boolean
  isSuccessful: boolean
  confirmations: Array<{
    owner: Address
  }>
}

//https://docs.safe.global/safe-core-api/available-services
const networkMap: { [key: number]: string } = {
  [gnosis.id]: 'gnosis-chain',
  [baseGoerli.id]: 'base-testnet',
  [baseSepolia.id]: 'base-sepolia',
  [mainnet.id]: 'mainnet',
  [optimism.id]: 'optimism',
  [polygon.id]: 'polygon',
  [base.id]: 'base',
  [arbitrum.id]: 'arbitrum',
  [goerli.id]: 'goerli',
  [sepolia.id]: 'sepolia'
}

/**
 * @see https://safe-transaction-mainnet.safe.global/
 * @see https://safe-transaction-base-sepolia.safe.global/api/v1/multisig-transactions/0xc02ba93a6f025e3e78dfceb5c9d4d681aa9aafc780ba6243d3d70ac9fdf48288/
 *
 * @param network network id
 * @param safeTxHash the "internal" safe tx hash
 * @returns 0xstring the executed transaction
 */
const resolveSafeTx = async (
  networkId: number,
  safeTxHash: Address,
  attempt = 1,
  maxAttempts = 10
): Promise<Address> => {
  const networkName = networkMap[networkId] || 'mainnet'

  if (attempt >= maxAttempts) {
    throw new Error(`timeout: couldnt find safeTx [${safeTxHash}] on [${networkName}]`)
  }
  const endpoint = `https://safe-transaction-${networkName}.safe.global`
  const url = `${endpoint}/api/v1/multisig-transactions/${safeTxHash}`

  const response = await fetch(url)

  if (response.status === 404) {
    console.debug(`didnt find safe tx [${safeTxHash}], assuming it's already the real one`)

    return safeTxHash
  }

  const responseJson: TxServiceApiTransactionResponse = await response.json()

  console.debug(`[${attempt}] looking up [${safeTxHash}] on [${networkName}]`, responseJson)

  // If the transaction is not yet dispatched by safe to the network it sets isSuccessful to null
  if (responseJson.isSuccessful === null) {
    await delay(1000 * attempt ** 1.75) //using a polynomial backoff, 2 grows too fast for my taste

    return resolveSafeTx(networkId, safeTxHash, attempt + 1, maxAttempts)
  }

  if (!responseJson.isSuccessful) {
    new Error('couldnt resolve safe tx')
  }

  console.debug(`found safe tx [${safeTxHash}] on [${networkName}]: ${responseJson.transactionHash}`)

  return responseJson.transactionHash
}
