import confetti from 'canvas-confetti'
import { useLayoutEffect } from 'react'

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min
}

type Variant = 'fireworks' | 'cannon' | 'realistic'

export const useConfetti = (variant: Variant) => {
  useLayoutEffect(() => {
    const canvas = document.createElement('canvas')
    canvas.style.position = 'absolute'
    canvas.style.width = '100vw'
    canvas.style.height = '100vh'
    canvas.style.top = '0'
    canvas.style.left = '0'
    canvas.style.pointerEvents = 'none'

    document.body.appendChild(canvas)

    const duration = 3000
    const animationEnd = Date.now() + duration
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

    switch (variant) {
      case 'cannon':
        {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          })
        }
        break
      case 'realistic':
        {
          const count = 200
          const defaults = {
            origin: { y: 0.7 },
            colors: ['FFFFFF', '36BE91', '248061', 'A2BEB1', '0B261D']
          }

          const fire = (particleRatio: number, opts: confetti.Options) => {
            confetti({
              ...defaults,
              ...opts,
              particleCount: Math.floor(count * particleRatio)
            })
          }

          fire(0.25, {
            spread: 26,
            startVelocity: 55
          })
          fire(0.2, {
            spread: 60
          })
          fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
          })
          fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
          })
          fire(0.1, {
            spread: 120,
            startVelocity: 45
          })
        }
        break
      case 'fireworks':
        setInterval(() => {
          const timeLeft = animationEnd - Date.now()

          const particleCount = 50 * (timeLeft / duration)
          // since particles fall down, start a bit higher than random
          confetti({
            ...defaults,
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
          })
          confetti({
            ...defaults,
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
          })
        }, 250)
    }

    return () => {
      document.body.removeChild(canvas)
    }
  }, [variant])
}
