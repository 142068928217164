import { ChainId, nativeAssetSupertokensByNetwork } from '@/config/networks'
import { Balances } from '@/types'
import { SuperTokenInfo, TokenInfo } from '@superfluid-finance/tokenlist'
import { cfAv1ForwarderABI, cfAv1ForwarderAddress, ierc20ABI } from 'evm-contracts'
import { zipObject } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useAccount, useBalance, useChainId, useReadContracts } from 'wagmi'
import { useTokenRelations } from './useTokenRelations'
import { useTorexTokens } from './useTorexTokens'

export const useBalances = () => {
  const { address: user } = useAccount()
  const userChainId = useChainId() as ChainId
  const { tokens: torexTokens } = useTorexTokens()
  const { getUnderlyingTokenOf } = useTokenRelations()

  const { superTokens, allTokens } = useMemo(() => {
    const { superTokens, underlyingTokens } = torexTokens.reduce<{
      superTokens: SuperTokenInfo[]
      underlyingTokens: TokenInfo[]
    }>(
      (acc, token) => {
        if (token.extensions?.superTokenInfo.type === 'Wrapper') {
          const underlyingToken = getUnderlyingTokenOf(token.address)

          if (underlyingToken) {
            acc.underlyingTokens.push(underlyingToken)
          }
        }

        acc.superTokens.push(token)

        return acc
      },
      { superTokens: [], underlyingTokens: [] }
    )

    const allTokens = [...superTokens, ...underlyingTokens]

    return { superTokens, allTokens }
  }, [torexTokens, getUnderlyingTokenOf])

  const { data: nativeAssetBalance, refetch: refetchNativeAssetBalance } = useBalance({
    query: {
      enabled: Boolean(user)
    },
    address: user
  })

  const {
    data: balancesData,
    isLoading,
    refetch: refetchTokenBalances
  } = useReadContracts({
    query: {
      enabled: Boolean(user),
      select: responses => {
        return zipObject(
          allTokens.map(({ address }) => address),
          responses.map(({ result }) => result as bigint)
        )
      }
    },
    contracts: allTokens.map(token => ({
      address: token.address,
      abi: ierc20ABI,
      functionName: 'balanceOf',
      args: [user]
    }))
  })

  const { data: netFlowsData, refetch: refetchNetFlows } = useReadContracts({
    query: {
      enabled: Boolean(user),
      select: responses => {
        return zipObject(
          superTokens.map(({ address }) => address),
          // @ts-ignore
          responses.map(({ result }) => result as bigint)
        )
      }
    },
    contracts: superTokens.map(token => ({
      address: cfAv1ForwarderAddress[userChainId],
      abi: cfAv1ForwarderABI,
      functionName: 'getAccountFlowrate',
      args: [token.address, user]
    }))
  })

  const balances: Balances[] = useMemo(() => {
    if (!balancesData || !netFlowsData || !superTokens || !nativeAssetBalance) {
      return []
    }

    return superTokens.map(superToken => {
      const superTokenBalance = balancesData[superToken.address]
      const netFlow = netFlowsData[superToken.address]

      const extendedSuperToken = {
        ...superToken,
        balance: superTokenBalance ?? 0n,
        netFlow: netFlow ?? 0n
      }

      if (superToken.extensions?.superTokenInfo.type === 'Wrapper') {
        const underlying = getUnderlyingTokenOf(superToken.address)

        if (!underlying) {
          throw new Error(`Underlying token not found for super token: ${superToken.address}`)
        }

        const underlyingBalance = balancesData[underlying.address]

        return {
          token: extendedSuperToken,
          underlying: {
            ...underlying,
            balance: underlyingBalance ?? 0n
          }
        }
      }
      if (superToken.extensions?.superTokenInfo.type === 'Native Asset') {
        return {
          token: extendedSuperToken,
          underlying: {
            ...nativeAssetSupertokensByNetwork[userChainId].underlyingToken,
            balance: nativeAssetBalance?.value ?? 0n
          }
        }
      }
      return {
        token: extendedSuperToken
      }
    })
  }, [balancesData, netFlowsData, nativeAssetBalance, superTokens, userChainId, getUnderlyingTokenOf])

  const refetchBalances = useCallback(() => {
    refetchNativeAssetBalance()
    refetchTokenBalances()
    refetchNetFlows()
  }, [refetchTokenBalances, refetchNativeAssetBalance, refetchNetFlows])

  return { balances, isLoading, refetchBalances }
}
