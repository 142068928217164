import { useEffect, useState } from 'react'
import { useRafLoop } from 'react-use'

export const ANIMATION_FREQUENCY = 50

const useFlowingBalance = (startingBalance: bigint, flowRate: bigint) => {
  const [lastTimeStamp, setLastTimeStamp] = useState(0)
  const [flowingBalance, setFlowingBalance] = useState(startingBalance)

  useEffect(() => {
    setFlowingBalance(startingBalance)
  }, [startingBalance])

  useRafLoop(time => {
    if (time - lastTimeStamp > ANIMATION_FREQUENCY) {
      setLastTimeStamp(time)
      setFlowingBalance(prev => prev + (flowRate * BigInt(Math.floor(time - lastTimeStamp))) / 1000n)
    }
  })

  return flowingBalance
}

export default useFlowingBalance
